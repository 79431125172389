import React from "react";
import Header from "../Home/header";
import BreadCrump from "../../component/Global/Breadcrump";
import Footer from "../Home/Footer";
import cafe_lounge from "../../images/pramaso-flier.jpg";
import WifiOutlinedIcon from "@mui/icons-material/WifiOutlined";
import ChargingStationOutlinedIcon from "@mui/icons-material/ChargingStationOutlined";
import SportsEsportsOutlinedIcon from "@mui/icons-material/SportsEsportsOutlined";
import MeetingRoomOutlinedIcon from "@mui/icons-material/MeetingRoomOutlined";
import TapasOutlinedIcon from "@mui/icons-material/TapasOutlined";
import EventSeatOutlinedIcon from '@mui/icons-material/EventSeatOutlined';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';

function Cafe() {
  return (
    <section className="pramaso-cafe">
      <Header />
      <BreadCrump currentPage={"Pramaso Cafe Lounge"} />
      <div className="main-content container mt-5">
        <div className="row justify-content-center mt-5 mb-5 pb-2">
          <div className=" text-center heading-section mt-5 mb-2">
            <h3 className="fw-bolder fw-bold">
              <span className="header text-info">Pramaso Cafe Lounge</span>
            </h3>
            <h2 className="fw-bolder">Pramaso Cafè Lounge & Events Center</h2>
          </div>
        </div>
        <div className="description_text">
          <div style={{ marginBottom: "3rem" }}>
            <p>
              <strong>Pramaso Cafe Lounge</strong> was established to fill a
              niche in the market for a modern, flexible, and well-appointed
              venue specifically tailored to the requirements of corporate
              meetings and events.
            </p>

            <p>
              By focusing on creating a conducive environment, fostering
              networking opportunities, and providing professional support, it
              aims to be a go-to destination for businesses seeking a top-tier
              event space.
            </p>
            <div className="activities-wrapper">
              <h3 className="header text-info">Services</h3>
              <ul className="activity-list">
                <li className="activity-list-item border-none">
                  <div className="icon">
                    <EventSeatOutlinedIcon />
                  </div>
                  <span>Event Space Rental</span>
                </li>
                <li className="activity-list-item border-none">
                  <div className="icon">
                    <TapasOutlinedIcon />
                  </div>
                  <span>Refreshment services</span>
                </li>
                <li className="activity-list-item border-none">
                  <div className="icon">
                    < Diversity3OutlinedIcon />
                  </div>
                  <span>Networking Events</span>
                </li>
                <li className="activity-list-item border-none">
                  <div className="icon">
                    <MeetingRoomOutlinedIcon />
                  </div>
                  <span>Private Business Meetings</span>
                </li>
                <li className="activity-list-item border-none">
                  <div className="icon">
                    <HandshakeOutlinedIcon />
                  </div>
                  <span>Collaborative Workspace</span>
                </li>
              </ul>
            </div>
            <div className="aminities-wrapper mt-3">
              <h3 className="header text-info">Amenities</h3>
              <ul className="aminities-list">
                <li className="aminities-list-item">
                  <div className="icon">
                    <WifiOutlinedIcon />
                  </div>
                  <span>High Speed WiFi</span>
                </li>
                <li className="aminities-list-item">
                  <div className="icon">
                    <MeetingRoomOutlinedIcon />
                  </div>{" "}
                  <span>Private Meeting Space</span>
                </li>
                <li className="aminities-list-item">
                  {" "}
                  <div className="icon">
                    <ChargingStationOutlinedIcon />
                  </div>{" "}
                  <span>Charging pots </span>
                </li>
                <li className="aminities-list-item">
                  <div className="icon">
                    <SportsEsportsOutlinedIcon />
                  </div>{" "}
                  <span>E-Games</span>
                </li>
                <li className="aminities-list-item">
                  <div className="icon">
                    <TapasOutlinedIcon />
                  </div>
                  <span>Light Snacks and Pasteries</span>
                </li>
              </ul>
            </div>
          </div>

          <img
            src={cafe_lounge}
            width={600}
            alt=""
            style={{ marginBottom: "3rem" }}
          />
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </section>
  );
}

export default Cafe;
