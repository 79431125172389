import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
// import { BusinessCenter } from "@material-ui/icons";
import CodeIcon from "@mui/icons-material/Code";
import PsychologyIcon from "@mui/icons-material/Psychology";
import HandshakeRoundedIcon from "@mui/icons-material/HandshakeRounded";

//
function Services() {
  return (
    <section className="agr-section services">
      <div className="container">
        <div className="row justify-content-center mb-5 pb-2">
          <div className="col-md-8 text-center heading-section">
            <span className="subheading">Services</span>
            <h2 className="mb-4">Our Services</h2>
            <p>
              With our dynamic and innovative nature, we offer wide range of
              services as listed below.
            </p>
          </div>
        </div>

        <div className="row justify-content-center mb-5 pb-2">
          <div className="pills w-100">
            <BasicTabs />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          className=""
          aria-label="basic tabs example"
        >
          <Tab label="Digital Skills Empowerment" {...a11yProps(0)} />
          <Tab label="Business Development Consultancy" {...a11yProps(1)} />
          <Tab label="Incubation and Acceleration Programs" {...a11yProps(2)} />
          <Tab label="Youth Tech-Innovation Development" {...a11yProps(3)} />
          <Tab label="Networking and Community Events" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <article className="bg-light service-content-wrapper">
          <div className="icon-wrapper">
            <CodeIcon height="3rem" width="3rem" />
          </div>
          <div className="text-content w-50">
            <h3 className="title">DIGITAL SKILLS EMPOWERMENT</h3>
            <p>
              We provide tailored digital skills training initiatives designed
              for building entrepreneurs and small, growing startups to bolster
              their digital literacy and proficiency in information and
              communication technology (ICT).
            </p>
          </div>
        </article>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <article className="bg-light service-content-wrapper">
          <div className="icon-wrapper">
            <HandshakeRoundedIcon />
          </div>
          <div className="text-content w-50">
            <h3 className="title">BUSINESS DEVELOPMENT CONSULTANCY</h3>
            <p>
              We offer customized consulting services to startups and micro,
              small, and medium-sized enterprises (MSMEs), nurturing their
              sustainable expansion and successful entry into the market.
            </p>
          </div>
        </article>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <article className="bg-light service-content-wrapper">
          <div className="icon-wrapper">
            <div className="icon d-flex justify-content-center align-items-center">
              <span className="flaticon-dropper"></span>
            </div>
          </div>
          <div className="text-content w-50">
            <h3 className="title">INCUBATION AND ACCELERATION PROGRAMS</h3>
            <p>
              Our programs offer startups with mentorship, resources, and
              funding opportunities; empowering them to thrive and achieve
              scalable growth.
            </p>
          </div>
        </article>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <article className="bg-light service-content-wrapper">
          <div className="icon-wrapper">
            <div className="icon d-flex justify-content-center align-items-center">
              <PsychologyIcon />
            </div>
          </div>
          <div className="text-content w-50">
            <h3 className="title">YOUTH TECH-INNOVATION DEVELOPMENT</h3>
            <p>
              We provide training, coaching, and mentorship programs to support
              the development of emerging Tech innovators, empowering them with
              vital skills and knowledge.
            </p>
          </div>
        </article>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <article className="bg-light service-content-wrapper">
          <div className="icon-wrapper">
            <div className="icon d-flex justify-content-center align-items-center">
              <span className="flaticon-first-aid-kit"></span>
            </div>
          </div>
          <div className="text-content w-50">
            <h3 className="title">NETWORKING AND COMMUNITY EVENTS</h3>
            <p>
              We organize a diverse range of events, meetups, and networking
              sessions to cultivate connections among entrepreneurs, potential
              collaborators, and investors within a nurturing community.
            </p>
          </div>
        </article>
      </CustomTabPanel>
    </Box>
  );
}
