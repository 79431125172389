import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import Ab_1 from "../../../images/abrafi_digital_studio/ab_1.jpg";
import Ab_2 from "../../../images/abrafi_digital_studio/ab_2.jpg";
import Ab_3 from "../../../images/abrafi_digital_studio/ab_3.jpg";
import Ab_4 from "../../../images/abrafi_digital_studio/ab_4.jpg";
import Ab_5 from "../../../images/abrafi_digital_studio/ab_5.jpg";
import Ab_6 from "../../../images/abrafi_digital_studio/ab_6.jpg";

export default function StandardImageList() {
  return (
<ImageList sx={{ width: 500, height: 650 }}>
 
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            src={`${item.img}?w=248&fit=crop&auto=format`}
            alt={item.title}
            loading="lazy"
            className='image_gallery_list'
          />
        
        </ImageListItem>
      ))}
    </ImageList>

  );
}

const itemData = [
  {
    img: Ab_1,
    title: 'ab studio 1',
  },
  {
    img: Ab_2,
    title: 'ab studio 2',
  },
  {
    img: Ab_3,
    title: 'ab studio 3',
  },
  {
    img: Ab_6,
    title: "ab studio 5",
  },
  {
    img: Ab_4,
    title: 'ab studio 4',
  },
  {
    img: Ab_5,
    title: "ab studio 5",
  },


  
];