import LoginForm from "./signinPage";
import SignUpForm from "./registerPage";
import { Box } from "@mui/material";
import { LoginUIStateProvider } from "../context/AuthContext";

import LoginImg from "../images/banner-slider__image/giz_me.jpg";
import Logo from "../images/logo.png";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//
const AuthPage = () => {
  return (
    <LoginUIStateProvider>
      <div className="auth">
        <div className="container auth-container">
          <section className="login-signup__container">
            <LoginForm />
            <SignUpForm />
          </section>
          <Box className="auth-banner">
            <section className="auth-banner-text">
              <a href="/" className="auth-logo" title="Click to go home">
                <img src={Logo} alt="Logo" />
              </a>
              <p className="subtitle callout">
                "Before enrolling on a free web development course at Agrico
                Hub, I had zero or no knowledge in web development. But after
                this course, I have develop a website for my startup which I
                have started generating income. <br />
                <strong>- Akwesi Micheal, Former Trainee</strong>"
              </p>
            </section>
            <Box
              className="auth-banner-bg-img"
              sx={{
                backgroundImage: `url(${LoginImg}) !important`,
              }}
            ></Box>
          </Box>
          <section className="toast-container">
            <ToastContainer autoClose="true" position="right" />
          </section>
        </div>
      </div>
    </LoginUIStateProvider>
  );
};
export default AuthPage;
