import React from "react";
import Header from "../Home/header";
import BreadCrump from "../../component/Global/Breadcrump";
import { Link } from "react-router-dom";
import flyer from "../../images/paid_courses/digital_media/flyer.jpg"
import voc_tr from "../../images/paid_courses/digital_media/voc_tr.jpg"
import Footer from "../Home/Footer";
function OpenPrograms() {
    return (
        <div className="open-project">
            <Header />
            <BreadCrump currentPage={"Opened Programs"} />
            <div className="main-content container mt-5">
                <div className="row justify-content-center mt-5 mb-5 pb-2">
                    <div className="col-md-8 text-center heading-section ">
                        <h3 className=" fw-bold">
                            <span className="header-2 text-info ff-mont">Programs opened for application <span className="my-3 text-primary outline-dashed">2</span></span>
                        </h3>
                        <hr className="hr" />
                    </div>
                </div>
                <div className="program-content-wrapper">
                    <div className="programs-content  gap-2">
                        <div className="program-info w-100">
                            <div className="program-title">
                                <h3 className="header text-primary ff-mont">Digital Media Training</h3>
                            </div>
                            <div className="program-info-text">
                                <b>
                                    The Digital Media Training Program aims to empower
                                    youth with essential skills and knowledge
                                    in digital media to enable them to start their own
                                    digital media start-up businesses.
                                </b>
                                <p>
                                    The program will run for three months, from October to December 2023,
                                    and will provide participants with comprehensive training in various
                                    aspects of digital media.
                                    <div className="course-info-card">
                                        <div className="card mt-4 flex center">
                                            <div className="card-header">
                                                <h3 className="card-title mt-2">What you will learn</h3>
                                            </div>
                                            <div className="card-bod">
                                                <ul>
                                                    <li>
                                                        <span className="circle"></span> <span>Photography</span>

                                                    </li>
                                                    <li>
                                                        <span className="circle"></span> <span>Graphic Design</span>
                                                    </li>
                                                    <li>
                                                        <span className="circle"></span><span> Social Media Marketing</span>
                                                    </li>
                                                    <li>
                                                        <span className="circle circle-last" last-of-type></span> <span>Entrepreneurship and Business Development</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </p>
                            </div>

                            <div className="basic-course-info">
                                <div className="mt-4 flex center">
                                    <h3 className="title">Basic Course Info</h3>
                                    <ul>
                                        <li>
                                            Suitable for: <span>Beginners</span>
                                        </li>
                                        <li>
                                            Mode of training: <span>Face to Face</span>
                                        </li>
                                        <li>
                                            Duration: <span>3 Months</span>
                                        </li>
                                        <li>
                                            Training Begins On: <span>9th October, 2023</span>
                                        </li>
                                        <li>
                                            Course Fee: <span>GH¢ 1,000</span>
                                        </li>
                                    </ul>
                                    <p className="text-danger">
                                        <i>
                                            * Please kindly note that Application ends on the 6th October, 2023
                                        </i>
                                    </p>
                                </div>
                            </div>
                            <div className="programs-application-link link">
                                <Link target="_blank" to="https://forms.gle/RaesGTSH5zsGei5J6" className="link-text flex-row gap-1">Enroll For this program
                                    <span className="ion-ios-arrow-forward icon"> </span>
                                </Link>
                            </div>
                        </div>
                        <div className="program-banner w-100">
                            <img src={flyer} alt="Digital Media Traning" className="program-flyer" width={'100%'} />
                        </div>
                    </div>

                    <hr className="hr mt-4" />
                    {/* Program two */}
                    <div className="programs-content  gap-2">
                        <div className="program-info w-100">
                            <div className="program-title">
                                <h3 className="header text-primary ff-mont">Vocational Training Program</h3>
                            </div>
                            <div className="program-info-text">
                                <b>
                                    In pursuit of promoting youth empowerment and economic development,
                                    we are excited to propose a comprehensive vocational skills training
                                    program for youth residing in the Techiman Municipality.

                                </b>
                                <p className="mt-2">
                                    This initiative aims to equip participants with valuable skills
                                    in producing detergents (liquid soap, afterwash, parazone,
                                    bar soap, washing powder), pastries
                                    (Turn over, cake, sausage roll,rockbuns, short bread), and fruit
                                    juice (orangepine, carodense, karadafa, mixed fruit juice).
                                    Additionally, the program will offer a side attraction training in
                                    fascinator making, allowing participants to explore diverse avenues
                                    for income generation.
                                    <div className="course-info-card">
                                        <div className="card mt-4 flex center">
                                            <div className="card-header">
                                                <h3 className="card-title mt-2">What you will learn</h3>
                                            </div>
                                            <div className="card-bod">
                                                <ul>
                                                    <li>
                                                        <span className="circle"></span> <span>Detergent Production</span>

                                                    </li>
                                                    <li>
                                                        <span className="circle"></span> <span>Pastries</span>
                                                    </li>
                                                    <li>
                                                        <span className="circle"></span><span> Fruit Juice Production</span>
                                                    </li>
                                                    <li>
                                                        <span className="circle circle-last" last-of-type></span> <span>Fascinator Making</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </p>
                            </div>

                            <div className="basic-course-info">
                                <div className="mt-4 flex center">
                                    <h3 className="title">Basic Course Info</h3>
                                    <ul>
                                        <li>
                                            Suitable for: <span>Beginners</span>
                                        </li>
                                        <li>
                                            Mode of training: <span>Face to Face</span>
                                        </li>
                                        <li>
                                            Duration: <span>3 Weeks</span>
                                        </li>

                                        <li>
                                            Training Start Date: <span>6th November, 2023</span>
                                        </li>
                                        <li>
                                            Course Fee: <span>GH¢ 1,000</span>
                                        </li>
                                    </ul>
                                    <p className="text-danger">
                                        <i>
                                            * Please kindly note that Application ends on the 21st October, 2023
                                        </i>
                                    </p>
                                </div>
                            </div>
                            <div className="programs-application-link link">
                                <Link target="_blank" to="https://docs.google.com/forms/d/e/1FAIpQLSfTUosTEpL7B-WKvq1BsLfEYt1aZoYIEdFXLNDN6JJgWJ9rqw/viewform?vc=0&c=0&w=1&flr=0&usp=mail_form_link" className="link-text flex-row gap-1">Enroll For this program
                                    <span className="ion-ios-arrow-forward icon"> </span>
                                </Link>

                            </div>

                        </div>
                        <div className="program-banner w-100">
                            <img src={voc_tr} alt="Digital Media Traning" className="program-flyer" width={'100%'} />
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    );
}

export default OpenPrograms;
