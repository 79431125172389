import React from "react";
import Header from "../Home/header";
import BreadCrump from "../../component/Global/Breadcrump";
import StandardImageList from "../../component/Global/abrafiImageGallery/imagegallery";

function AbStudio() {
  return (
    <section>
      <Header />
      <BreadCrump currentPage={"Abrafi Digital Studio"} />
      <div className="main-content container mt-5">
        <div className=" justify-content-center mt-5 mb-5">
          <div className=" text-center heading-section mt-5 mb-5">
            <h3 className="fw-bolder fw-bold">
              <span className="header text-info">Digital Photo Studio</span>
            </h3>
            <h2 className="fw-bolder">Abrafi Digital Studio</h2>
          </div>
          <div className="main-content grid mt-3">
            <div className="left-section">
              <div className="abrafi-main-content mb-4">
                <div className="">
                  <section>
                    <p>
                      <strong> At Abrafi Digital Studio,</strong> we transform
                      moments into timeless memories. As a premier photoshoot
                      studio, we pride ourselves on capturing the essence of
                      your special occasions with creativity and precision.
                    </p>
                  </section>

                  <section>
                    <h2 className="header">Our Vision</h2>
                    <p>
                      Our vision is to be the go-to destination for individuals
                      and families seeking exceptional photography services. We
                      believe in the power of visual storytelling and aim to
                      provide our clients with stunning images that will be
                      cherished for generations.
                    </p>
                  </section>

                  <section>
                    <h2 className="header">What Sets Us Apart</h2>
                    <ul>
                      <li>
                        <strong>Professionalism:</strong> Our team of
                        experienced photographers is dedicated to delivering
                        professionalism at every step.
                      </li>
                      <li>
                        <strong>Creativity:</strong> At Abrafi Digital Studio,
                        creativity knows no bounds. We approach each photoshoot
                        with an artistic eye, ensuring that every frame tells a
                        unique story.
                      </li>
                      <li>
                        <strong>State-of-the-Art Facilities:</strong> Equipped
                        with state-of-the-art technology and a spacious studio,
                        we provide an environment that enhances the photography
                        experience.
                      </li>
                    </ul>
                  </section>

                  <section>
                    <h2 className="header">Our Services</h2>
                    <ul>
                      <li>
                        <strong>Portrait Photography:</strong> Capture the
                        essence of your personality with our expert portrait
                        sessions.
                      </li>
                      <li>
                        <strong>Event Photography:</strong> From weddings to
                        corporate events, we specialize in capturing the magic
                        of every moment.
                      </li>
                      <li>
                        <strong>Product Photography:</strong> Highlight the best
                        features of your products with our professional product
                        photography services.
                      </li>
                    </ul>
                  </section>
                  <section>
                    <h2 className="header">Contact Us</h2>
                    Ready to create lasting memories? Contact Abrafi Digital
                    Studio for all your photography needs. Let us turn your
                    moments into masterpieces.
                  </section>
                </div>
              </div>
            </div>
            <div className="right-section">
              <div className="image-gallery-container">
                <StandardImageList />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AbStudio;
