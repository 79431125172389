// import firebase from "firebase/app";
// import "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSd23Pvw_uOH8Sa3pYGKHQ",
  authDomain: "deepblue-crm.firebaseapp.com",
  projectId: "agricohubgh",
  storageBucket: "agricohubgh.a.b",
  messagingSenderId: "723244269974",
  appId: "1:750871269974:web:e268cf05456515f87821f7",
  measurementId: "G-5721343fe",
};

// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = app.auth();

//export const provider = new auth.GoogleAuthProvider();
