import { Box, TextField, Typography, Checkbox } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import AgrButton from "../component/Global/Button/button";
import {
  LoginUIStateConsumer,
  AuthUIContext,
  AuthConsumer,
} from "../context/AuthContext";
import { useAuth } from "../context/AuthContext";
import googleIconSvg from "../images/Vectors/googleIcon.svg";
///
const SignUpForm = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  //get current user, error and signUpNewUser method from useAuth
  const { currentUser, signUpNewUser, error, isLoading } = useAuth();

  const handleFormSubmit = (values) => {
    console.log(values);
    const email = values.email;
    const password = values.password;
    signUpNewUser(email, password);
    console.log("current user email", currentUser.email);
  };

  return (
    <AuthConsumer>
      {(authContext) => {
        return (
          <LoginUIStateConsumer>
            {(contextValue) => {
              console.log("Register context", contextValue);
              const { logInUIActive, setUIActive } = contextValue;
              return (
                <Box
                  className="register-container"
                  display={logInUIActive ? "grid" : "none"}
                  sx={{
                    "& input": {
                      backgroundColor: "#fff",
                    },
                  }}
                >
                  <Box
                    sx={{
                      "& h2": {
                        textAlign: "center",
                      },
                    }}
                  >
                    <h2 className="title">Create a new account</h2>
                    {/* Register with google */}
                    <Box className="sign-in__with-google" mb={"2rem"}>
                      <AgrButton
                        iconSrc={googleIconSvg}
                        text="Sign up with Google"
                        textColor={"#123"}
                        borderColor={"#123"}
                        borderRadius={"5px"}
                        onClickAction={() => {
                          return signUpNewUser();
                        }}
                      />
                      {/* or sign up with email and password */}
                    </Box>

                    <Box className="divider">
                      <hr />
                      <Typography>or sign up with</Typography>
                      <hr />
                    </Box>
                    {/* Google Registeration */}

                    <Formik
                      onSubmit={handleFormSubmit}
                      initialValues={initialValues}
                      validationSchema={checkoutSchema}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <Box
                            display="grid"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                              "& > div": {
                                gridColumn: isNonMobile ? undefined : "span 4",
                                              },
                            }}
                          >
                            <TextField
                              className="input-field"
                              fullWidth
                              variant="filled"
                              type="text"
                              label="First Name"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.firstName}
                              name="firstName"
                              error={!!touched.firstName && !!errors.firstName}
                              helperText={touched.firstName && errors.firstName}
                              sx={{
                                gridColumn: "span 2",
                                paddingRight: "9px",
                              }}
                            />
                            <TextField
                              fullWidth
                              className="input-field"
                              variant="filled"
                              type="text"
                              label="Last Name"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.lastName}
                              name="lastName"
                              error={!!touched.lastName && !!errors.lastName}
                              helperText={touched.lastName && errors.lastName}
                              sx={{
                                gridColumn: "span 2",
                                paddingLeft: "9px",
                              }}
                            />
                            <TextField
                              placeholder="some@one.com"
                              className="input-field"
                              fullWidth
                              variant="filled"
                              type="text"
                              label="Email"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.email}
                              name="email"
                              error={!!touched.email && !!errors.email}
                              helperText={touched.email && errors.email}
                              sx={{ gridColumn: "span 4" }}
                            />
                            <TextField
                              hidden
                              fullWidth
                              className="input-field"
                              variant="filled"
                              type="text"
                              label="Staff ID"
                              placeholder="e.g Staff ID"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.staff_id}
                              name="staff_id"
                              error={!!touched.staff_id && !!errors.staff_id}
                              helperText={touched.staff_id && errors.staff_id}
                              sx={{ gridColumn: "span 4" }}
                            />

                            <TextField
                              fullWidth
                              className="input-field"
                              variant="filled"
                              type="password"
                              label="Password"
                              placeholder="xxxxxxx"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.password}
                              name="password"
                              error={!!touched.password && !!errors.password}
                              helperText={touched.password && errors.password}
                              sx={{ gridColumn: "span 4" }}
                            />
                            <TextField
                              placeholder="Confirm Password"
                              fullWidth
                              className="input-field"
                              variant="filled"
                              type="password"
                              title="Create a new password"
                              label="Confirm Password"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.confirmPassword}
                              name="confirmPassword"
                              error={
                                !!touched.confirmPassword &&
                                !!errors.confirmPassword
                              }
                              helperText={
                                touched.confirmPassword &&
                                errors.confirmPassword
                              }
                              sx={{ gridColumn: "span 4" }}
                            />
                          </Box>
                          <Box className="terms-and-condition">
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              ml={"-0.7rem"}
                              mt={"1rem"}
                            >
                              <Checkbox
                                className="checkbox"
                                required
                                id="terms-checkbox"
                              />
                              <Typography
                                className="text-info"
                                fontSize={"12px"}
                              >
                                By clicking the checkbox you agree to the terms
                                and conditions applicable to collecting and
                                saving your data.
                              </Typography>
                            </Box>
                          </Box>
                          <Box display="flex" justifyContent="center" mt="20px">
                            <button
                              type="submit"
                              className="auth-btn btn"
                              // disabled={isLoading}
                              style={{
                                cursor: isLoading ? "not-allowed" : "pointer",
                                userSelect: isLoading ? "none" : "inherit",
                              }}
                            >
                              CREATE ACCOUNT
                            </button>
                          </Box>
                          <Box mt={"1rem"} mb={"1rem"}>
                            <Typography className="p-text row center w-100">
                              Already having an account?
                              <span style={{ marginLeft: "4px" }}>
                                <Link
                                  to="#"
                                  onClick={() => {
                                    return setUIActive(!logInUIActive);
                                  }}
                                >
                                  {" "}
                                  Sign in
                                </Link>
                              </span>
                            </Typography>
                          </Box>
                        </form>
                      )}
                    </Formik>
                  </Box>
                </Box>
              );
            }}
          </LoginUIStateConsumer>
        );
      }}
    </AuthConsumer>
  );
};

// const phoneRegExp =
// /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  staff_id: yup.string().required("required"),
  confirmPassword: yup
    .string()
    .min(7, "Password length should be greater than 7")
    .required("required"),
  password: yup
    .string()
    .min(7, "Password length should be greater than 7")
    .required("required"),
});
const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  contact: "",
  staff_id: "",
  confirmPassword: "",
  password: "",
};

export default SignUpForm;
