import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/logo.png";
function Footer() {
  return (
    <div className="footer">
      <footer className="agr-footer agr-bg-dark agr-section">
        <div className="container">
          <div className="row mb-5">
            <div className="col-md">
              <div className="agr-footer-widget mb-5 logo">
                <img src={Logo} alt="" height={"45px"} />
                <p>
                  Agrico Hub is a Business Innovation Hub that uses Innovative
                  Business Development and Digital Technology solutions to
                  support early stage entrepreneurs, and Small & Growing
                  Businesses (SGBs) in Agribusiness, Trade & Commerce and
                  Services
                </p>
              </div>
              <div className="agr-footer-widget mb-5">
                <h2 className="agr-heading-2">Have a Question?</h2>
                <div className="block-23 mb-3">
                  <ul>
                    <li>
                      <span className="icon icon-map-marker"></span>
                      <span className="text">
                        Diasempa- Techiman Bono East Region. Ghana
                      </span>
                    </li>
                    <li>
                      <Link to="#">
                        <span className="icon icon-phone"></span>
                        <span className="text">+233 32 2498 877</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="mailto:info@agricohubgh.com">
                        <span className="icon icon-envelope"></span>
                        <span className="text">info@agricohubgh.com</span>
                      </Link>
                    </li>
                  </ul>
                  {/* footer-social */}
                  <ul className="agr-footer-social mt-3">
                    <li className="">
                      <a
                        href="https://www.twitter.com/AgricoG"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="icon-twitter"></span>
                      </a>
                    </li>
                    <li className="">
                      <a
                        href="https://www.facebook.com/Agricohubgh"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="icon-facebook"></span>
                      </a>
                    </li>
                    <li className="">
                      <a
                        href="https://www.linkedin.com/in/agrico-hub-a59295210/"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <span className="icon-linkedin"></span>
                      </a>
                    </li>
                  </ul>
                </div>

                <ul className="agr-footer-social list-unstyled float-md-left float-lft mt-3">
                  <li className="agr-animate">
                    <a
                      rel="noreferrer"
                      href="https://www.twitter.com/AgricoG"
                      target="_blank"
                    >
                      <span className="icon-twitter"></span>
                    </a>
                  </li>
                  <li className="agr-animate">
                    <a
                      rel="noreferrer"
                      href="https://www.facebook.com/Agricohubgh"
                      target="_blank"
                    >
                      <span className="icon-facebook"></span>
                    </a>
                  </li>
                  <li className="agr-animate">
                    <a
                      rel="noreferrer"
                      href="https://www.instagram.com"
                      target="_blank"
                    >
                      <span className="icon-instagram"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/**ENd of first row */}
            <div className="col-md">
              <div className="agr-footer-widget mb-5 ml-md-4">
                <h2 className="agr-heading-2">Links</h2>
                <ul className="list-unstyled">
                  <li>
                    <Link to="/">
                      <span className="ion-ios-arrow-round-forward mr-2"></span>
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/about">
                      <span className="ion-ios-arrow-round-forward mr-2"></span>
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <span className="ion-ios-arrow-round-forward mr-2"></span>
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/project">
                      <span className="ion-ios-arrow-round-forward mr-2"></span>
                      Projects
                    </Link>
                  </li>
                  <li>
                    <Link to="/gallery">
                      <span className="ion-ios-arrow-round-forward mr-2"></span>
                      Gallery
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog">
                      <span className="ion-ios-arrow-round-forward mr-2"></span>
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">
                      <span className="ion-ios-arrow-round-forward mr-2"></span>
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="agr-footer-widget mb-5 ml-md-4">
                <h2 className="agr-heading-2">Services</h2>
                <ul className="list-unstyled">
                  <li>
                    <Link to="#">
                      <span className="ion-ios-arrow-round-forward mr-2"></span>
                      Business Coaching
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <span className="ion-ios-arrow-round-forward mr-2"></span>
                      IT Development
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <span className="ion-ios-arrow-round-forward mr-2"></span>
                      Startup Incubation
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <span className="ion-ios-arrow-round-forward mr-2"></span>
                      Digital Skills Training
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <span className="ion-ios-arrow-round-forward mr-2"></span>
                      Fund Support For Women in Trade Commerce
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/**End of second row */}
            {/*Send us Mail*/}
            <div className="col-md">
              <div className="agr-footer-widget mb-5">
                <h2 className="agr-heading-2 ">Opening Hours</h2>
                <h4 className="open-hours pl-4 text-muted">
                  <span className="ion-ios-time mr-3"></span>We are open Mon -
                  Fri
                </h4>
                <h4 className="open-hours pl-4 text-muted">
                  <span className="ion-ios-time mr-3"></span>08:30 am : 05:00 pm
                </h4>
              </div>
              <div className="agr-footer-widget mb-5">
                <h2 className="agr-heading-2 ">Mail Us!</h2>
                <form action="#" className="subscribe-form">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control mb-2 text-center"
                      placeholder="Enter email address"
                    />
                    <input
                      type="submit"
                      value="Send Us Mail"
                      className="form-control submit px-3 font-weight-bold"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/*copyright section*/}
          <div className="row">
            <div className="col-md-12 text-center font-weight-bold">
              <p className="text-white fs-3 text">
                Copyright &copy;
                {new Date().getFullYear()} All rights reserved | Developed
                Agrico Hub Tech Team{" "}
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
