import { Box, Checkbox, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";

import googleIconSvg from "../images/Vectors/googleIcon.svg";
import AgrButton from "../component/Global/Button/button";
import { Link } from "react-router-dom";

import {
  LoginUIStateConsumer,
  AuthUIContext,
  AuthConsumer,
} from "../context/AuthContext";
import { useAuth } from "../context/AuthContext";

const LoginForm = () => {
  //get current user, error and signUpNewUser method from useAuth
  const { currentUser, signInUser, error, signInUserWithGoogle } = useAuth();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  // handleForm method
  const handleFormSubmit = (values) => {
    console.log(values);
    const email = values.email;
    const password = values.password;
    signInUser(email, password);
  };

  return (
    <LoginUIStateConsumer>
      {(state) => {
        const { logInUIActive, setUIActive } = state;
        return (
          <Box
            width={"80%"}
            textAlign={"center !important"}
            m="20px"
            display={logInUIActive === false ? "flex" : "none"}
            flexDirection={"column"}
            alignContent={"flex-start"}
            justifyItems={"flex-start"}
            sx={{
              transition: "0.8s all ease-in-out",
            }}
          >
            <h2 className="title">Welcome Back!</h2>
            <p className="subtitle">Please enter your details to continue</p>
            <Box className="sign-in__with-google" mb={"2rem"}>
              <AgrButton
                iconSrc={googleIconSvg}
                text="Sign in with Google"
                textColor={"#123"}
                borderColor={"#123"}
                borderRadius={"5px"}
                onClickAction={() => {
                  return signInUserWithGoogle();
                }}
              />
              {/* or sign in with email and password */}
            </Box>

            <Box className="divider">
              <hr />
              <p>or sign in with</p>
              <hr />
            </Box>

            <Box>
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box
                      display="grid"
                      gap="10px"
                      width={"100%"}
                      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                      sx={{
                        "& > div": {
                          gridColumn: isNonMobile ? undefined : "span 4",
                        },
                        "& input": {
                          backgroundColor: "#fff",
                        },
                      }}
                    >
                      <TextField
                        placeholder="some@one.com"
                        className="input-field"
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        name="email"
                        error={!!touched.email && !!errors.email}
                        helperText={touched.email && errors.email}
                        sx={{ gridColumn: "span 4" }}
                      />

                      <TextField
                        placeholder="Password"
                        fullWidth
                        className="input-field"
                        variant="filled"
                        type="password"
                        label="Password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        name="password"
                        error={!!touched.password && !!errors.password}
                        helperText={touched.password && errors.password}
                        sx={{ gridColumn: "span 4" }}
                      />
                      {/*show password field*/}

                      <TextField
                        placeholder="Password"
                        fullWidth
                        className="input-field"
                        variant="filled"
                        type="text"
                        label="Password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        name="password"
                        error={!!touched.password && !!errors.password}
                        helperText={touched.password && errors.password}
                        sx={{ gridColumn: "span 4", display: "none" }}
                      />
                    </Box>
                    <Box
                      display={"flex"}
                      flexDirection="row"
                      justifyContent={"space-between"}
                      alignItems="center"
                      color={"GrayText"}
                    >
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        ml={"-0.7rem"}
                        mt={"1rem"}
                      >
                        <Checkbox className="checkbox" />
                        <Typography className="p-text">Remember Me</Typography>
                      </Box>
                      <Link
                        to={"password_reset"}
                        style={{ textDecoration: "underline" }}
                      >
                        Forgot Password?
                      </Link>
                    </Box>
                    <Box display="flex" justifyContent="center" mt="20px">
                      <button className="auth-btn btn">Login</button>
                    </Box>
                    <Box mt={"1rem"}>
                      <Typography
                        className="p-text"
                        display={"flex"}
                        justifyContent="center"
                      >
                        Don't have an account yet?
                        <span
                          style={{
                            marginLeft: "4px",
                            color: "lightgreen !important",
                          }}
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              return setUIActive(!logInUIActive);
                            }}
                          >
                            Sign up for free
                          </Link>
                        </span>
                      </Typography>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </Box>
        );
      }}
    </LoginUIStateConsumer>
  );
};

const checkoutSchema = yup.object().shape({
  email: yup.string().email("invalid email").required("required"),
  password: yup
    .string()
    .min(7, "Password length should be greater than 7")
    .required("required"),
});
const initialValues = {
  email: "",
  password: "",
};

export default LoginForm;
