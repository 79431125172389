import React from "react";
import Header from "../Home/header";
import BreadCrump from "../../component/Global/Breadcrump/index";
import { blogData } from "../../data/blog_data";
import { Link } from "react-router-dom";
import { ArrowForward, ArrowForwardIos } from "@material-ui/icons";
import TruncatedText from "../../component/Global/wordCount/wordCount";
function Blog() {

  const setInnerHtml = (mainText)=>{
    return {__html: mainText};
 }

  return (
    <div className="blog">
      <Header />
      <BreadCrump currentPage={"Blog"} />
      <div className="main-content container mt-5">
        <div className=" justify-content-center mt-5 mb-5">
          <div className=" text-center heading-section mt-5 mb-5">
            <h3 className="fw-bolder fw-bold">
              <span className="header text-info">Blog</span>
            </h3>
            <h2 className="fw-bolder">Recent News</h2>
          </div>
          {/* main content */}
          <div className="blog-wrapper center mt-3">
            {blogData.length ? (
              <>
                {blogData.map((data) => {
                  return (
                    <div className="blog-card">
                      <div className="blog-image-card">
                        <img
                          className="blog-image img"
                          src={data.image_url}
                          alt="Blog Media" 
                        />
                      </div>
                      <div className="blog-content-card">
                        <h3 className="blog-title">{data.title}</h3>
                        <p className="text-content"  >   <TruncatedText text={data.description} /></p>
                      </div>
                      <Link to={`/blog/${data.id}`} className="link-btn blog-btn">
                        Read More
                        <span>
                          <ArrowForward />
                        </span>
                      </Link>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                <h3 className="title">Sorry No Blog for you at the moment.</h3>
                <p>Kindly refresh the page and try again.</p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
