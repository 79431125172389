import React from "react";
import Footer from "../Home/Footer";
import Header from "../Home/header";
import BreadCrump from "../../component/Global/Breadcrump";
import contactBg from "../../images/background/contact_bg.png";

function Contact() {
  return (
    <div className="about">
      <Header />
      <BreadCrump currentPage={"Contact"} />

      <div className="map-sec space">
        <div className="map">
          <iframe
            title="googleMap"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3644.7310056272386!2d89.2286059153658!3d24.00527418490799!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39fe9b97badc6151%3A0x30b048c9fb2129bc!2sAngfuzsoft!5e0!3m2!1sen!2sbd!4v1651028958211!5m2!1sen!2sbd"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
        <div className="container">
          <div className="map-contact">
            <h2 className=" header text-info">Get in Touch</h2>
            <p className="mt-n1 mb-30">
              Have a inquiry or some feedback for us? Fill out the form below to
              contact our team. Or you can visit us anytime for your inquiries.
            </p>
            <div className="contact-info">
              <div className="contact-info_icon">
                <span className="icon-map"></span>
              </div>
              <div className="media-body">
                <h4 className="contact-info_title">Our Address</h4>
                <span className="contact-info_text">
                  Diasempa - Off VRA Road, Techiman - Ghana
                </span>
              </div>
            </div>
            <div className="contact-info">
              <div className="contact-info_icon">
                <span className="icon-phone"></span>
              </div>
              <div className="media-body">
                <h4 className="contact-info_title">Phone Number</h4>
                <span className="contact-info_text">
                  <span>
                    Mobile:
                    <a href="tel:+233322498877" className="ml-2">
                      (+233) - 32 -249 - 8877
                    </a>
                    <br />
                  </span>
                  <span>
                    <a href="tel:+233244016046" className="ml-2">
                      (+233) - 24 -401 - 6046
                    </a>
                  </span>
                </span>
              </div>
            </div>
            <div className="contact-info">
              <div className="contact-info_icon">
                <span className="icon-watch"></span>
              </div>
              <div className="media-body">
                <h4 className="contact-info_title">Hours of Operation</h4>
                <span className="contact-info_text">
                  <span>
                    Monday - Friday <br /> 09:00 - 20:00
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* contact form container */}
      <section className="space">
        <div className="container contact-container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-7 col-sm-9">
              <div className="title-area text-center"></div>
            </div>
          </div>
          <div
            className="contact-form-wrap"
            style={{
              backgroundImage: `url(${contactBg})`,
            }}
          >
            <h2 className="header text-info">Contact Us!</h2>
            <h2 className="border-title">Have Any Questions?</h2>
            <p className="mt-n1 mb-25 sec-text">
              Send us mail anytime you have any questions and our team will get
              back to you soon.
            </p>
            <form
              // Send DATA to our backend
              action="https://backend"
              method="POST"
              className="contact-form ajax-contact"
            >
              <div className="row">
                <div className="form-group col-md-6 mt-4">
                  <input
                    type="text"
                    className="form-control style3"
                    name="name"
                    id="name"
                    placeholder="Enter Your Name"
                    required
                  />
                  <span className="icon-user"></span>
                </div>
                <div className="form-group col-md-6 mt-4">
                  <input
                    type="email"
                    className="form-control style3"
                    name="email"
                    id="email"
                    placeholder="Email Address"
                    required
                  />
                  <span className="icon-envelope"></span>
                </div>
                <div className="form-group col-md-6 mt-4">
                  <select
                    name="subject"
                    id="subject"
                    className="form-select style3 h-100 pl-1"
                    required
                  >
                    <option
                      value=""
                      disabled="disabled"
                      // selected="selected"
                      hidden
                    >
                      Select Subject
                    </option>
                    <option value="Program Registration">
                      Program Registration
                    </option>
                    <option value="Acceleration Program">
                      Acceleration Program
                    </option>
                    <option value="Business Incubation">
                      Business Incubation
                    </option>
                    <option value="General Inquiries">General Inquiries</option>
                  </select>
                  {/* <span className="icon-arrow-down"></span> */}
                </div>
                <div className="form-group col-md-6 mt-4">
                  <input
                    type="tel"
                    className="form-control style3"
                    name="number"
                    id="number"
                    placeholder="Phone Number"
                  />
                  <span
                    className="icon-phone"
                    style={{
                      transform: "rotate(270deg)",
                    }}
                  ></span>
                </div>
                <div className="form-group col-12 mt-4">
                  <textarea
                    name="message"
                    id="message"
                    cols="30"
                    rows="3"
                    className="form-control style3"
                    placeholder="Your Message"
                    required
                  ></textarea>
                  <span className="icon-comment"></span>
                </div>
                <div className="form-btn col-12 mt-10">
                  <button
                    type="submit"
                    className=" btn-outline-primary contact-btn"
                  >
                    Send Message<span className="icon-arrow-right ml-4"></span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Contact;
