import React, { useState } from "react";
import Header from "../Home/header";
import BreadCrump from "../../component/Global/Breadcrump";
import { Link } from "react-router-dom";
import creditUnionLogo from "../../images/credit_union/credit_union.png";
import creditUnionDoc from "../../assets/doc/credit_union_brochure.pdf";
import Footer from "../Home/Footer";
import { ReadMore } from "@mui/icons-material";

function StartupCooperations() {
  const [openPdfReader, setOpenPdfReader] = useState(false);

  if (!openPdfReader) {
    document.getElementById("document-reader");
    console.log("Error");
  }

  return (
    <div className="credit-union">
      <Header />
      <BreadCrump currentPage={"Credit Union"} />
      <div className="main-content container mt-5">
        <div className="row justify-content-center mt-5 mb-5 pb-2">
          <div className="col-md-8 text-center heading-section ">
            <h2 className=" fw-bold text-info">
              Startup Cooperative Credit Union
            </h2>
          </div>
        </div>
        {/*    wrapper for main content*/}
        <div className="credit-union-wrapper">
          <div className="grid inner-content">
            <img src={creditUnionLogo} alt="" className="credit-union-logo" />
            <div className="credit-union-info">
              <div className="header">
                <h2 className="title">Who We Are</h2>
              </div>
              <div className="text-content">
                <p>
                  <strong> Startups Co-operative Credit Union Ltd. </strong> is
                  a member-owned where individual members hold minimum shares.
                  Our commitment at Startups Co-operative Credit Union is
                  unwavering as we strive to bolster the entrepreneurial journey
                  of startups. We offer a suite of customized financial products
                  and services designed to address the unique financial
                  requirements of startups.
                  <br />
                  <br />
                  <strong>In addition to our core offerings, </strong>we go beyond the
                  conventional to foster success for our startups. Through
                  innovations, strategic investments, and valuable partnerships,
                  we aim to enhance the probability of success for the startups
                  we serve. At Startups Co-operative Credit Union, your
                  entrepreneurial dreams matter, and we're here to help
                  you achieve them.
                </p>

                <button
                  className="mb-5 reader-toggler"
                  onClick={() => {
                    setOpenPdfReader(!openPdfReader);
                  }}
                >
                  Read More
                  <span>
                    <ReadMore />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        {openPdfReader && (
          <>
            <div className="document-reader" id="document-reader">
              <iframe
                zoom="50%"
                className={"pdf-iframe"}
                title="Credit Union Document"
                src={creditUnionDoc}
                frameBorder="0"
              ></iframe>
            </div>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default StartupCooperations;
