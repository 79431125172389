import George from "../../images/staff_images/george.jpeg";
import Justice from "../../images/staff_images/justice.jpeg";
import David from "../../images/staff_images/david.jpeg";
import Kak from "../../images/staff_images/kak.jpg";
import Lute from "../../images/staff_images/lute.jpg";
import Abigail from "../../images/staff_images/Abigail.jpg";
import Abigail_Oteng from "../../images/staff_images/Abigail_Oteng.jpg";
import Eunice from "../../images/staff_images/Eunice.jpg";

import Testimonial from "./Testimony/Testimonial";
import AboutText from "./AboutText";
import Services from "./Services";

function MainContent() {
  return (
    <div className="main-content">
      <AboutText />
      {/*Services */}
      <Services />
      {/* Our Staff*/}
      <section className="our-staff agr-section agr-no-pt">
        <div className="container">
          <div className="row justify-content-center mb-5 pb-2">
            <div className="col-md-8 text-center heading-section ">
              <h3 className="fw-bolder fw-bold">
                <span className="header text-info">Staffs</span>
              </h3>
              <h2 className="mb-4">Meet Our Qualified Staffs</h2>
              <p>
                The Hub parades a team of qualified professionals and technical
                support staff who have over 10 years' experience in
                professional, vocational, and technical training in youth
                entrepreneurship development, IT innovations, Agribusiness and
                related enterprise areas.
              </p>
            </div>
          </div>
          {/*Display list of staff */}
          <div className="row">
            {/* Staff Goerge */}
            <div className="col-md-6 col-lg-3">
              <div className="staff">
                <div className="img-wrap d-flex align-items-stretch">
                  <img
                    className="img align-self-stretch "
                    src={George}
                    alt=""
                  />
                </div>
                <div className="text pt-3 text-center">
                  <h3 className="staff-name">Goerge Antwi-Boasiako</h3>
                  <span className="position mb-2">C.E.O</span>
                  <div className="faded">
                    <p>C.E.O of Agrico Hub Ghana.</p>
                    <ul className="agr-social text-center">
                      <li className="">
                        <a href="#">
                          <span className="icon-twitter"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-facebook"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-google-plus"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-instagram"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Staff Eunice */}
            <div className="col-md-6 col-lg-3">
              <div className="staff">
                <div className="img-wrap d-flex align-items-stretch">
                  <img
                    className="img align-self-stretch "
                    src={Eunice}
                    alt=""
                  />
                </div>
                <div className="text pt-3 text-center">
                  <h3 className="staff-name">Eunice Baah</h3>
                  <span className="position mb-2">Hub Manager</span>
                  <div className="faded">
                    <p>
                      Hub Maanager at Agrico Hub, Mentor and a Lecture with
                      strong leadership and communication skills.
                    </p>
                    <ul className="agr-social text-center">
                      <li className="">
                        <a href="#">
                          <span className="icon-twitter"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-facebook"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-google-plus"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-instagram"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Staff David */}
            <div className="col-md-6 col-lg-3">
              <div className="staff card-img-top profile">
                <div className="img-wrap d-flex align-items-stretch">
                  <img className="img align-self-stretch " src={David} alt="" />
                </div>
                <div className="text pt-3 text-center">
                  <h3 className="staff-name">Mr. David D. Yeboah</h3>
                  <span className="position mb-2">
                    Business Development Officer
                  </span>
                  <div className="faded">
                    <p>
                      Business Developer at Agrico Hub, Startup Coach, Mentor
                      and a Design Thinking practitioner with a background in
                      Accounting and Fundraising.
                    </p>
                    <ul className="agr-social text-center">
                      <li className="">
                        <a href="#">
                          <span className="icon-twitter"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-facebook"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-google-plus"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-instagram"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Staff Lute */}
            <div className="col-md-6 col-lg-3">
              <div className="staff">
                <div className="img-wrap d-flex align-items-stretch">
                  <a href="https://yakubulute.netlify.app/"><img className="img align-self-stretch " src={Lute} alt="" /></a>
                </div>
                <div className="text pt-3 text-center">
                  <h3 className="staff-name"> <a href="https://yakubulute.netlify.app/">Yakubu Lute</a></h3>
                  <span className="position mb-2"><a href="https://yakubulute.netlify.app/">Technical Lead</a></span>
                  <div className="faded">
                    <p>
                      Technical Lead. Experienced software engineer with a passion for 
                      developing innovative programs that expedite the efficiency and
                       effectiveness of organizational success.
                    </p>
                    <ul className="agr-social text-center">
                      <li className="">
                        <a href="#">
                          <span className="icon-twitter"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-facebook"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-google-plus"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-instagram"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Staff  KAK*/}
            <div className="col-md-6 col-lg-3">
              <div className="staff">
                <div className="img-wrap d-flex align-items-stretch">
                  <img className="img align-self-stretch " src={Kak} alt="" />
                </div>
                <div className="text pt-3 text-center">
                  <h3 className="staff-name">Kwadwo Asumani</h3>
                  <span className="position mb-2">Tech Associate</span>
                  <div className="faded">
                    <p>
                      Tech Associate, with strong background in IT and
                      Programming including several languages such as
                      JavaScript, Java and C#.
                    </p>
                    <ul className="agr-social text-center">
                      <li className="">
                        <a href="#">
                          <span className="icon-twitter"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-facebook"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-google-plus"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-instagram"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Staff ABigail Oteng Marfo */}
            <div className="col-md-6 col-lg-3">
              <div className="staff">
                <div className="img-wrap d-flex align-items-stretch">
                  <img
                    className="img align-self-stretch "
                    src={Abigail_Oteng}
                    alt=""
                  />
                </div>
                <div className="text pt-3 text-center">
                  <h3 className="staff-name">Abigail Oteng Marfo</h3>
                  <span className="position mb-2">Community Manager/ Programms Coordinator</span>
                  <div className="faded">
                    <p>
                      A Community Associate who's a social media and a digital
                      marketing enthusiast with a Bachelor of Commerce
                    </p>
                    <ul className="agr-social text-center">
                      <li className="">
                        <a href="#">
                          <span className="icon-twitter"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-facebook"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-google-plus"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-instagram"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Staff Justice */}
            <div className="col-md-6 col-lg-3">
              <div className="staff">
                <div className="img-wrap d-flex align-items-stretch">
                  <img
                    className="img align-self-stretch "
                    src={Justice}
                    alt=""
                  />
                </div>
                <div className="text pt-3 text-center">
                  <h3 className="staff-name">Tanye Isaac</h3>
                  <span className="position mb-2">Media Lead</span>
                  <div className="faded">
                    <p>
                      Media Lead. A photographer, programmer, and instructor
                    </p>
                    <ul className="agr-social text-center">
                      <li className="">
                        <a href="#">
                          <span className="icon-twitter"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-facebook"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-google-plus"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-instagram"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Staff Abigail Anane Frimpong */}
            <div className="col-md-6 col-lg-3">
              <div className="staff">
                <div className="img-wrap d-flex align-items-stretch">
                  <img
                    className="img align-self-stretch "
                    src={Abigail}
                    alt=""
                  />
                </div>
                <div className="text pt-3 text-center">
                  <h3 className="staff-name">Abigail Anane Frimpomaa</h3>
                  <span className="position mb-2">Office Assistant</span>
                  <div className="faded">
                    <p>Office Assistant</p>
                    <ul className="agr-social text-center">
                      <li className="">
                        <a href="#">
                          <span className="icon-twitter"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-facebook"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-google-plus"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-instagram"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Section Testimonial */}
      <section className="testimonials">
        <section className="agr-section testimony-section bg-light">
          <div className="container">
            <div className="row justify-content-center mb-5 pb-2">
              <div className="col-md-8 text-center heading-section ">
                <span className="subheading">Testimonials</span>
                <h2 className="mb-4">
                  Our Trainees/Stakeholders Say About Us{" "}
                </h2>
                <p>
                  With our inspiring training programs, dynamic and innovative
                  training environments, experienced trainers and deddicated
                  staffs our trainees are always satisfied with our services.
                  This is what they have to say about Agrico Hub GH services.
                </p>
              </div>
            </div>
            <div className="">
              <Testimonial />
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}

export default MainContent;
