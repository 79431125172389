import React from "react";
//import pages
import Home from "./Pages/Home";
import Contact from "./Pages/Contact/Contact";
import About from "./Pages/About/About";
import Blog from "./Pages/Blog/Blog";
import Project from "./Pages/Projects/Project";
import Error from "./Pages/Error/Error";
import Gallery from "./Pages/Gallery/Gallery";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Auth from "../src/auth/index";
import OpenPrograms from "./Pages/open_programs/OpenPrograms";
import PramasoCafe from "./Pages/cafe";
import StartupCooperations from "./Pages/startups_cooperation";
import AbStudio from "./Pages/AbStudio";
import { blogData } from "./data/blog_data";
import BlogComponent from "./component/Global/blogComponent";

function App() {
  return (
    <div>
      {/* TODO: display navbar and footer always even in page 404 for easy navigation*/}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/project" element={<Project />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogComponent />} />      
          {blogData.map((data) => (
            <Route key={data.url} path={data.url} element={<data.element />} />
          ))}
          <Route path="/pramaso-cafe" element={<PramasoCafe />} />
          <Route path="/ab-studio" element={<AbStudio />} />
          <Route
            path="/startups-cooperative"
            element={<StartupCooperations />}
          />
          <Route path="/applynow" element={<OpenPrograms />} />
          <Route path="/*" element={<Error />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
