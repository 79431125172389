import React, { useEffect, useState } from 'react'
import Header from '../../../Pages/Home/header'
import BreadCrump from '../Breadcrump'
import { blogData } from "../../../data/blog_data";
import { useParams } from 'react-router-dom';

import CircleIcon from '@mui/icons-material/Circle';
function BlogComponent() {
    const params = useParams();
    let id = params.id;
    const [title, setTitle] = useState('');
    const [author, setAuthor] = useState('');
    const [date, setDate] = useState('');
    const [mainText, setMainText] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [readTime, setReadTime] = useState('');
  
    const setInnerHtml = ()=>{
       return {__html: mainText};
    }
    useEffect(() => {
      blogData.forEach((data) => {
        if (data.id === id) {
          setTitle(data.title);
          setAuthor(data.author);
          setDate(data.date);
          setMainText(data.description);
          setImageUrl(data.image_url);
          setReadTime(data.read_time);
        }
      });
    }, [blogData, id]);



  return (
    <section>
    <Header />
    <BreadCrump currentPage={`Blog/${id}`} />
    <div className="main-content mt-5">
      <div className="container blog-container"
      style={{maxWidth:"700px"}}
      >
        <div className="blog-page-wrapper">
          <div className="blog-header">
            <h1 className="blog-view-title">     
             {title}
            </h1>
          </div>
          <div className="blog_author_wrapper">
            <p className="blog-author">Author: {author} </p>
            <p className="date flex">{readTime} <span className='circle-icon'></span> {date}</p>
            
          </div>
          <div className="blog-main__content">
            <img width={600} src={imageUrl} alt={id} />
            <div className="main-text" dangerouslySetInnerHTML={setInnerHtml()}>
            {/* {mainText} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default BlogComponent