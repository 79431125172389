import React from "react";
import Footer from "../Home/Footer";
import Header from "../Home/header";
import BreadCrump from "../../component/Global/Breadcrump";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { IconButton, ImageListItemBar } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

// import all images
import {
  //aweled
  aweled_1,
  aweled_2,
  aweled_3,
  aweled_5,

  //deled
  deled_2,
  deled_3,
  deled_5,
  deled_7,

  //digital inclusion
  bamire_1,
  bamire_2,
  bamire_3,
  bamire_4,

  // dmb_1
  dmb_1,
  dmb_2,
  dmb_4,
  dmb_5,

  //escape program
  escape_2,
  escape_1,
  escape_6,
  escape_10,

  // gdiw
  gdiw_1,
  gdiw_2,
  gdiw_3,
  gdiw_4,

  // nap
  nap_1,
  nap_2,
  nap_3,
  nap_4,

  // pase
  pase_1,
  pase_2,
  pase_3,
  pase_4,

  // pretech
  pretech_2,
  pretech_3,
  pretech_4,
  pretech_5,
} from "./imageList";

function Project() {
  return (
    <div className="project">
      <Header />
      <BreadCrump currentPage={"Projects"} />
      <section className="project-container">
        <div className="container">
          <div className=" text-center heading-section mt-5 mb-5">
            <h3 className="fw-bolder fw-bold">
              <span className="header text-info">Projects</span>
            </h3>
            <h2 className="mb-4">Most Popular Projects</h2>
          </div>
          <div className="project-content">
            {/* Project list */}
            <ProjectList />
          </div>
        </div>
      </section>
      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Project;

// re-usable component
export function TitlebarImageList({ itemData }) {
  return (
    <ImageList sx={{ width: 500, height: 450 }}>
      {itemData.map((item) => {
        return (
          <ImageListItem>
            <img
              src={`${item.img}?w=248&fit=crop&auto=format`}
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
            <ImageListItemBar
              title={item.title}
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(255, 255, 255, 0.7)" }}
                  aria-label={`info about ${item.title}`}
                >
                  <InfoIcon />
                </IconButton>
              }
            />
          </ImageListItem>
        );
      })}
    </ImageList>
  );
}

const ProjectTextList = ({ item, index }) => {
  return (
    <article className="prj-list__wrapper">
      <ul>
        <li key={index}>
          <h3 className="project-list__header">{item.program}</h3>
          <p className="project-info__text">{item.text}</p>
        </li>
      </ul>
    </article>
  );
};

const ProjectList = () => {
  return (
    <div>
      {projectData.map((data, index) => {
        return (
          <>
            <div key={index}>
              <div className="project-content__item">
                <div className="project-text">
                  <ProjectTextList index={index} item={data} />
                </div>
                <div className="image">
                  <TitlebarImageList itemData={data.itemData} key={index} />
                </div>
              </div>
              <hr className="mb-3 hr" />
            </div>
          </>
        );
      })}
    </div>
  );
};

const projectData = [
  {
    program: "ESCAPE Accelerator program - (2023-2024)",
    text: ` The ESCAPE Accelerator program is a dynamic 6-month initiative
     tailored to support and elevate 20 startups within the Bono East region.
      Developed in collaboration with the National Entrepreneurship and 
      Innovation Plan (NEIP), this program operates under the umbrella of 
    the Ghana Economic Transformation Program. Bolstered by the support of
     the World Bank and the Ministry of Finance in Ghana, ESCAPE Program is 
     driven by a series of strategically designed activities aimed at nurturing 
     and propelling the growth of these startups. The startups will undergo 
     intensive Mentorship, Business Model Refinement, Market Validation, Access 
     to Funding, Specialized Training, Networking Opportunities, Prototype Development,
     Investor Pitch Preparation and Post-Acceleration Support`,
    itemData: [
      {
        img: escape_6,
        title: "MCE Courtesy Visit to startups",
        rows: 2,
        cols: 2,
        featured: true,
      },
      {
        img: escape_2,
        title: "Startups doing presentation",
      },
      {
        img: escape_1,
        title: "Startups doing presentation",
      },
      {
        img: escape_10,
        title: "Startups doing presentation",
        cols: 2,
      },
    ],
  },

  {
    program:
      "Digitalization & Entrepreneurship for Local Economic Development - (DELED) Program - (2021 - 2022)",
    text: `
    The DELED program, facilitated by GIZ (DTC), actively bolsters local economies 
    through concentrated efforts. In parallel, DELED empowered 65 rural women in 
    Fiaso and Krobo with digital proficiency from February to April 2022. 
Moreover, a significant undertaking encompassed training 85 young apprentices in
 Digital and Startup skills and 87 MSMEs in E-commerce training in Nkoranza, 
 Atebubu, and Techiman from April to August 2023. 
These targeted initiatives underscore Agrico Hub's dedication to fostering digital 
competencies, entrepreneurship, and local economic advancement, supported by GIZ 
Digital Transformation Center and the eSkills4Girls program.
    `,
    itemData: [
      {
        img: deled_2,
        title: "Training session - Fiaso",
        rows: 2,
        cols: 2,
        featured: true,
      },
      {
        img: deled_7,
        title: "Training Session - Krobo",
      },
      {
        img: deled_3,
        title: "Training Session - Fiaso",
      },
      {
        img: deled_5,
        title: "Training Session - Krobo",
        cols: 2,
      },
    ],
  },
  {
    program:
      " Ghana Digital Innovations Week (GDIW) - Bono East Edition - (2021)",
    text: `The Bono East edition of the Ghana Digital Innovations Week (GDIW) was
     successfully held in November 2021. Facilitated by GIZ (DTC) and GIFEC, this
      regional event provided a platform for 100 stakeholders, innovators, and 
      startups to showcase their impactful contributions to the digital landscape.
In October 2022, a comprehensive ICT Training program was organized for District 
Labour Officers in the Bono East region. This training, effectively facilitated 
by GIZ MEP, was designed with the specific goal of enhancing the knowledge and 
proficiency of District Labour Officers in utilizing digital tools. 
The primary focus of this training was to equip these officers with the necessary
 skills to effectively support and promote small businesses within the region, thereby contributing to the advancement of employment and labor-related initiatives.
 `,
    itemData: [
      {
        img: gdiw_1,
        title: "",
        rows: 2,
        cols: 2,
        featured: true,
      },
      {
        img: gdiw_2,
        title: "",
      },
      {
        img: gdiw_3,
        title: "",
      },
      {
        img: gdiw_4,
        title: "",
        cols: 2,
      },
    ],
  },
  {
    program: "PRE-TECH (2021 - 2022)",
    text: ` In collaboration with Ghana Tech Lab, Agrico Hub has implemented a Pre-Tech program designed for young unemployed ladies in the Bono East region.  This endeavor empowered 206 unemployed women 
    through comprehensive digital and startup skills training from 
     February 2021 to October 2022 in the Bono East region. 
Covering disciplines like graphic design, photography, web and mobile app 
development, as well as AgriTech, this holistic training not only fostered digital
 proficiency but also paved the way for startup incubation and internships. 
 Such an integrated approach significantly elevated employability and self-sufficiency, 
 propelled by the synergistic contributions of Ghana Tech Lab, Soronko Academy, the 
 MasterCard Foundation, and the Ministry of Communication and Digitalization.
`,
    itemData: [
      {
        img: pretech_5,
        title: "Mobile App Dev Training Session",
        rows: 2,
        cols: 2,
        featured: true,
      },
      {
        img: pretech_2,
        title: "Web App Dev Training",
      },
      {
        img: pretech_3,
        title: "Presentation on Design Prototype",
      },
      {
        img: pretech_4,
        title: "Web App Dev Training",
        cols: 2,
      },
    ],
  },
  {
    program: "PATHWAY TO SUSTAINABLE EMPLOYMENT (PaSE) - (2021 - 2022)",
    text: ` In collaboration with Ghana Tech Lab, Agrico Hub successfully
     executed the PaSE program in the Bono East region. The PaSE program 
     was a pivotal component of the MasterCard Foundation's Young Africa 
     Works initiative. This endeavor empowered 158 unemployed women and 
     youth through comprehensive digital and startup skills training from 
     April 2021 to October 2022 in the Bono East region. 
Covering disciplines like graphic design, photography, web and mobile app 
development, as well as AgriTech, this holistic training not only fostered digital
 proficiency but also paved the way for startup incubation and internships. 
 Such an integrated approach significantly elevated employability and self-sufficiency, 
 propelled by the synergistic contributions of Ghana Tech Lab, Soronko Academy, the 
 MasterCard Foundation, and the Ministry of Communication and Digitalization.
`,
    itemData: [
      {
        img: pase_1,
        title: "Web App Dev Training",
        rows: 2,
        cols: 2,
        featured: true,
      },
      {
        img: pase_2,
        title: "Web App Dev. Training",
      },
      {
        img: pase_3,
        title: "Web App Dev. Training",
      },
      {
        img: pase_4,
        title: "Web App Dev. Training",
        cols: 2,
      },
    ],
  },
  {
    program: " NATIONAL ACCELERATION PROGRAM - (2022)",
    text: `Implemented in collaboration with Ghana Tech Lab, the 
    National Acceleration Program saw 22 startups in the Bono East 
    region undergoing a 6months Acceleration from June to November 2022. 
    These startups underwent a comprehensive acceleration process 
    that included invaluable components such as business mentorship, 
    coaching, and formalization support. This strategic initiative played 
    a pivotal role in nurturing the growth and development of these startups, 
    enhancing their potential for success in the competitive business landscape.
    `,
    itemData: [
      {
        img: nap_1,
        title: "National Acceleration Program",
        rows: 2,
        cols: 2,
        featured: true,
      },
      {
        img: nap_2,
        title: "National Acceleration Program",
      },
      {
        img: nap_3,
        title: "National Acceleration Program",
      },
      {
        img: nap_4,
        title: "National Acceleration Program",
        cols: 2,
      },
    ],
  },
  {
    program: " DIGITAL MEDIA BOOTCAMP - (2022)",
    text: `The Digital Media Bootcamp was tailored to empower 15 
    unemployed youth with fundamental digital media skills, with a
    particular emphasis on photography, videography, graphic design
    and social media marketing. Running from February to April 2023,
     this immersive program supplied participants with the requisite
     expertise to venture into digital media. `,
    itemData: [
      {
        img: dmb_1,
        title: "Training Session",
        rows: 2,
        cols: 2,
        featured: true,
      },
      {
        img: dmb_2,
        title: "Training Session",
      },
      {
        img: dmb_5,
        title: "Training Session",
      },
      {
        img: dmb_4,
        title: "Field Practice",
        cols: 2,
      },
    ],
  },
  {
    program: " DIGITAL INCLUSION PROGRAM - (2022)",
    text: ` The Digital Inclusion Program was designed to provide basic digital 
    skills to 50 women in the Agricultural value chain. This initiative, carried
     out between December 2021 and May 2022 by with support from Plan International
      Ghana Women Voice and Leadership, aimed to empower women with essential digital 
      competencies including introduction to digital marketing, 
    records keeping, financial literacy and cyber security alert.
    Additionally, the program facilitated engagement with 25 stakeholders
     to promote women's digital inclusion, emphasizing the importance of
      a collaborative approach in fostering a digitally inclusive environment.`,
    itemData: [
      {
        img: bamire_1,
        title: "Training Session - Bamire",
        rows: 2,
        cols: 2,
        featured: true,
      },
      {
        img: bamire_2,
        title: "Training Session - Bamire",
      },
      {
        img: bamire_3,
        title: "Training Session - Bamire",
      },
      {
        img: bamire_4,
        title: "Training Session - Bamire",
        cols: 2,
      },
    ],
  },
  {
    program:
      "ACCELERATING WOMEN ENTERPRISES FOR LOCAL ECONOMIC DEVELOPMENT (AWELED) - (2023)",
    text: `The AWELED Program initiated in partnership with Plan International’s 
    Women Voice and Leadership (WVL) to bolster economic opportunities for women.
     This encompassed providing Vocational skills training to 40 unemployed women
      to facilitate the initiation of income-generating activities. 
Additionally, the program enhanced the capacities of 30 women in Agro-Processing 
through tailored skills in Product Development and Digital Marketing. Recognizing
 the pivotal role of media, the AWELED initiative extended its scope to involve 
 journalists. 25 Journalists from Media Houses in the Bono and Bono East region were
  equipped with Digital Advocacy skills, along with training in Media Law and Ethics,
   to actively promote and support women-led enterprises.
 `,
    itemData: [
      {
        img: aweled_1,
        title: "Vocational Training for Women",
        rows: 2,
        cols: 2,
        featured: true,
      },
      {
        img: aweled_2,
        title: "Vocational Training for Women",
      },
      {
        img: aweled_3,
        title: "Photography Training for Media Personels",
      },
      {
        img: aweled_5,
        title: "Digital Marketing Training for Media Personels",
        cols: 2,
      },
    ],
  },
];
